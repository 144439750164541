import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/fr"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextMosaic from "../components/ui-sections/text-mosaic"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const OrigensPage = (props) => {
	const heroText = "Origine";
	const sentenceText = "Tout commence en juin 1969";
	const titleAvi = "Tout commence en juin 1969";
	const titlePare = "Le grand saut, garder l'essence";
	const titlePere = "Le cachet gastronomique du Pere";
	const contentAvi = "En juin 1969, le grand-père Pere et la grand-mère Carmeta inaugurent Cal Pere del Maset. Le restaurant a été inoubliable dès le premier jour par la famille Palau Sardà, où la fille a célébré son mariage le jour de l'ouverture. Et ce restaurant de viande grillée, tenu par les grands-parents, et deux petits maquereaux comme le père et l'oncle étaient alors, augmentait la capacité des salles à manger, de la cuisine et, par conséquent, du menu. Avec un pic, ils ont fait cuire des épaules, des chèvres, des lapins et aussi sont nés les ragoûts et le four que nous trouvons aujourd'hui au menu. Les grands-parents ont jeté les bases de ce qu'est le restaurant aujourd'hui. Les fondations de trois générations différentes à ce jour.";
	const contentPare = "Le père a succédé à notre grand-père, et lui et notre mère ont fait du restaurant une référence dans l'Alt Penedès, la cuisine du meilleur produit. Entre les années 1980 et 2000, le restaurant s'est considérablement développé avec la création de salles à manger privées, la cave, et la rénovation complète de la cuisine et des salles à manger. Mais garder l'âme, l'essence du premier jour. Cal Pere del Maset est un endroit où s'accumulent des souvenirs de toutes sortes, des générations de familles qui sont venues avec leurs grands-parents et qui viennent maintenant avec leurs enfants. Bonne nourriture et bonne boisson, mais aussi nostalgie et sentiments. Et cette essence est ce que le père et la mère lui ont toujours donné avec sa personnalité. Suite à l'héritage des grands-parents, afin que nos clients, amis, connaissances et étrangers viennent manger et se sentir chez eux."
	const contentPere = "El Pere est la troisième génération, et il dirige aujourd'hui, avec sa mère, la gestion quotidienne du restaurant. Ayant grandi sur des braises et des poêles, dès son plus jeune âge, il a toujours été clair qu'il voulait se consacrer à la cuisine. Il a commencé à griller du pain quand il n'avait que 9 ans, et a grandi en combinant les études et en aidant toujours dans la cuisine. Après avoir terminé ses études, il est allé se former dans des restaurants prestigieux dans le monde de l'étoile Michelin, tels que Les Crayères (Reims, France) ou Drolma du chef Fermí Puig à Barcelone. Et aujourd'hui, depuis plus d'une décennie maintenant, il maintient une offre gastronomique saisonnière, combinant le menu de sa vie avec des menus de dégustation, des plats de saison et des accords pour les palais les plus exigeants. Et comme les grands-parents, le père et la mère, maintenir un traitement proche et familier."
	const textSectionText1 = "\"Les origines sont les grands-parents. Le style, les braises, le feu, les ragoûts, le sauté, le toast, le toucher, le traitement, l'effort et le sacrifice, le caractère, la pulsion, la lutte, la vie, une partie de qui nous sommes et de ce qui est à venir. Les grands-parents. Notre cher grand-père Pere et notre chère grand-mère Carmeta.\""
	const textSectionText2 = "\"Ne jamais se rendre.\" De grand-père à père et de père à Pere et moi."


	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Origine"
	    	lang="fr"
	    	description={textSectionText1}
	    />
		  <HeroImage 
		  	titleText={heroText} 
		  	sentenceText={sentenceText}
		  	image="casa_antiga" 
		  />
		  <TextSection textSectionText={textSectionText1} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allOrigensImagesFrYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 3]} // This will be changed to `[2, 3]` later
	          images={data.allOrigensImagesFrYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	    <TextMosaic 
	    	titleAvi={titleAvi}
	    	titlePare={titlePare}
	    	titlePere={titlePere}
	    	contentAvi={contentAvi}
	    	contentPare={contentPare}
	    	contentPere={contentPere}
	    />
	    <TextSection textSectionText={textSectionText2} />
	    <HeroImage  image="paisatge" />
	  </Layout>
	)
}

export default OrigensPage
